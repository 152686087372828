<template>
  <div class="article-page-container" v-if="renderArticle">
    <CicularSubmenu class="circular__submenu" :onArticlePage="true"/>
    <div class="banner-image-container">
      <b-img-lazy :src="imageSrc" class="article-image"></b-img-lazy>
    </div>
    <div class="info-container" v-if="this.getUserContent">
      <div class="article-metadata">
        <h3 class="article-heading">{{title}}</h3>
        <div class="article-interaction-info">
          <div class="time-container">
            <img src="../../assets/images/svg/article_black_24dp.svg" alt="articleIcon" class="article-icon">
            <span class="time-total">{{timeTotal}} mins</span>
          </div>
          <div class="article-ratings">
            <AwesomeVueStarRating
                class="normal_star_ratings"
                :key="averageRating"
                :disabled="true"
                :star="averageRating"
                :starsize="stillStars.starsize"
                :hasresults="stillStars.hasresults"
                :hasdescription="stillStars.hasdescription"
            />
          </div>
          <div class="article-source">
            <p class="p_tag">Source<span class="source">{{source}}</span></p>
          </div>
          <div class="article-metrics">
            <img
                alt=""
                class="bookmark"
                :src=bookmarkColor
                @click="changeBookmark()"
            />
            <div class="add-to-playlist-button">
              <p class="copy_20_square p_tag">&#43;</p>
            </div>
            <div class="metric_container">
              <img
                  alt=""
                  class="comments_symbol"
                  src="https://static.overlay-tech.com/assets/886d571a-4973-4bd0-be95-1fd6c162f2f1.svg"
              />
              <p class="p_tag">{{ comments }}</p>
            </div>
            <div class="metric_container">
              <img
                  alt=""
                  class="heart-button"
                  :src=heartColorRed
              />
              <p class="heart-text p_tag">{{ likes }}</p>
            </div>
            <div class="metric_container">
              <div class="zoomi_index-box">
                <p class="copy_20_square p_tag">Z1</p>
              </div>
              <p class="zoomi_index-text p_tag">57%</p>
            </div>
            <div class="metric_container">
              <div class="zoomi_index-box">
                <p class="copy_20_square p_tag">Z2</p>
              </div>
              <p class="zoomi_index-text p_tag">44%</p>
            </div>
          </div>

          </div>
        </div>
      <div class="article-data">
        <div class="readable-text" v-html="articleDescription"></div>
<!--        Bottom Component-->
        <div class="bottom-interaction-container">
          <div class="ratings-container">
            <div class="stars">
              <AwesomeVueStarRating
                  class="still_star_ratings_article"
                  :disabled="stillStars.disabled"
                  :maxstars="5"
                  :star="5"
                  :starsize="stillStars.starsize"
                  :hasresults="stillStars.hasresults"
                  :hasdescription="stillStars.hasdescription"
              />
                <AwesomeVueStarRating
                    class="still_star_ratings_article"
                    :disabled="stillStars.disabled"
                    :maxstars="4"
                    :star="4"
                    :starsize="stillStars.starsize"
                    :hasresults="stillStars.hasresults"
                    :hasdescription="stillStars.hasdescription"
                />
                <AwesomeVueStarRating
                    class="still_star_ratings_article"
                    :disabled="stillStars.disabled"
                    :maxstars="3"
                    :star="3"
                    :starsize="stillStars.starsize"
                    :hasresults="stillStars.hasresults"
                    :hasdescription="stillStars.hasdescription"
                />

                <AwesomeVueStarRating
                    class="still_star_ratings_article"
                    :disabled="stillStars.disabled"
                    :maxstars="2"
                    :star="2"
                    :starsize="stillStars.starsize"
                    :hasresults="stillStars.hasresults"
                    :hasdescription="stillStars.hasdescription"
                />

                <AwesomeVueStarRating
                    class="still_star_ratings_article"
                    :disabled="stillStars.disabled"
                    :maxstars="1"
                    :star="1"
                    :starsize="stillStars.starsize"
                    :hasresults="stillStars.hasresults"
                    :hasdescription="stillStars.hasdescription"
                />

            </div>
            <div class="rating_bars_article">
              <div class="article_rating_rect">
                <div class="article_rating_rect-red" id="5_star"></div>
              </div>
              <div class="article_rating_rect">
                <div class="article_rating_rect-red" id="4_star"></div>
              </div>
              <div class="article_rating_rect">
                <div class="article_rating_rect-red" id="3_star"></div>
              </div>
              <div class="article_rating_rect">
                <div class="article_rating_rect-red" id="2_star"></div>
              </div>
              <div class="article_rating_rect">
                <div class="article_rating_rect-red" id="1_star"></div>
              </div>
            </div>
            <div class="article_numerics">
              <div class="avg_rating_article">
                {{averageRating}}
              </div>
              <div class="no_of_ratings_article">
                {{ totalUsersRated }} Ratings
              </div>
            </div>

          </div>
          <div class="rate-like">
            <div class="article_interaction-rate_this_lesson">
              <AwesomeVueStarRating
                  class="article_interaction-star_ratings"
                  :key="parseInt(starRatingData.star)"
                  :star="parseInt(starRatingData.star)"
                  :disabled="starRatingData.disabled"
                  :starsize="starRatingData.starsize"
                  :hasresults="starRatingData.hasresults"
                  :hasdescription="starRatingData.hasdescription"
              />
              <p class="p_tag">
              <span v-if="getIsLessonRated">
                Your Ratings
              </span>
                <span v-else>
                Rate this lesson
              </span>
              </p>
            </div>
            <div class="like-interaction">
              <img
                  alt=""
                  class="article-info-like"
                  :src=heartColor
                  @click="changeHeart()"
              />
              {{likedText}}
            </div>
            <div class="what_people_are_saying">
              What people are saying
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

</template>
<script>
import { socialvue } from '@/config/pluginInit'
import CicularSubmenu from '@/components/CircularSubmenu/CircularSubmenu.vue'
import AwesomeVueStarRating from '@/components/AwesomeVueStarRating/AwesomeVueStarRating.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Article',
  components: {
    CicularSubmenu,
    AwesomeVueStarRating
  },
  data () {
    return {
      ratingReceived: false,
      getIsLessonRated: false,
      progressBarVarient: 'danger',
      starRatingData: {
        star: 0, // the rating given by user
        hasresults: false,
        hasdescription: false,
        starsize: '1x', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
        disabled: false
      },
      stillStars: {
        hasresults: false,
        hasdescription: false,
        starsize: '1x', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
        disabled: true
      },
      ratingFills: {
        star_5: '',
        star_4: '',
        star_3: '',
        star_2: '',
        star_1: ''
      },
      timeTotal: 0,
      title: '',
      source: '',
      likedText: '',
      ratings: 0,
      comments: 0,
      likes: 0,
      renderArticle: false,
      imageSrc: '',
      totalUsersRated: 0,
      z1: 48,
      z2: 90,
      heartBeatInterval: 0,
      averageRating: 0,
      articleDescription: '',
      bookmarkColor: '',
      heartColor: 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg',
      heartColorRed: 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg',
      toggle: false,
      isSaved: false,
      contentState: {
        isSaved: null,
        liked: null,
        rating: null,
        timeViewed: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsLessonRated',
      'getAnalyticsState',
      'getUserContent'
    ])
  },
  methods: {
    ...mapActions([
      'RESET_STATE',
      'GET_USER_CONTENT',
      'UPDATE_USER_CONTENT_STATE'
    ]),
    ...mapMutations([
      'setCompleted',
      'setTotalDuration',
      'setPrevPlaybackPosition',
      'setPlaybackPosition',
      'setLoggedUser',
      'setPage',
      'setType',
      'setAction',
      'setName',
      'setContentId',
      'setCategory',
      'setPublisherId',
      'setPublisherName',
      'setRating',
      'setReview',
      'setLike',
      'setBookmark',
      'setTopic',
      'setLevel'

    ]),
    sendHeartBeat () {
      this.bombIsAway('textEvent', 'heart_beat')
    },
    fillStarRating () {
      // TODO AFTER Itegration - you may try a for loop here
      let elem = document.getElementById('5_star')
      elem.style.width = (((this.ratingFills.star_5 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('4_star')
      elem.style.width = (((this.ratingFills.star_4 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('3_star')
      elem.style.width = (((this.ratingFills.star_3 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('2_star')
      elem.style.width = (((this.ratingFills.star_2 * 100) / this.totalUsersRated) || 0) + '%'
      elem = document.getElementById('1_star')
      elem.style.width = (((this.ratingFills.star_1 * 100) / this.totalUsersRated) || 0) + '%'
      this.ratingReceived = true
      console.log('Setting ratings true', this.ratingReceived)
    },
    setAnalyticsContentData (dataObject) {
      this.setType(dataObject.content.contentType)
      this.setPage('ARTICLE_PAGE')
      this.setName(dataObject.content.title)
      this.setContentId(dataObject.content.id)
      this.setCategory(dataObject.content.category.name)
      this.setPublisherId(dataObject.content.publisher.id)
      this.setPublisherName(dataObject.content.publisher.name)
      this.setRating(dataObject.rating)
      if (dataObject.review) {
        this.setReview(dataObject.review)
      }
      this.setBookmark(dataObject.isSaved)
      this.setLike(dataObject.liked !== 0)
      this.setCompleted(dataObject.isCompleted)
      this.setPrevPlaybackPosition(dataObject.timeViewed)
      this.setPlaybackPosition(dataObject.timeViewed)
      this.setTotalDuration(dataObject.content.timeTotal)
    },
    bombIsAway (eventType, action) {
      console.log(eventType + ' ' + action)
      this.setAction(action)
      console.log(this.getAnalyticsState)
      // eslint-disable-next-line no-undef
      divolte.signal(eventType, this.getAnalyticsState)
    },
    updateState () {
      this.UPDATE_USER_CONTENT_STATE({
        contentId: this.$route.params.contentId,
        payload: this.contentState
      })
    },
    changeHeart () {
      if (this.toggle) {
        this.likedText = 'Like the Lesson'
        this.heartColor = 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg'
        this.contentState.liked = 0
        this.setLike(false)
        this.bombIsAway('textEvent', 'unlike')
        this.likes--
      } else {
        this.likedText = 'Liked'
        this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        this.contentState.liked = 1
        this.setLike(true)
        this.bombIsAway('textEvent', 'like')
        this.likes++
      }
      this.updateState()
      this.toggle = !this.toggle
    },
    changeBookmark () {
      console.log('Bookmark ', this.isSaved)
      if (this.isSaved) {
        this.bookmarkColor = require('@/assets/images/svg/bookmarks_white_18dp.svg')
        this.setBookmark(false)
        this.bombIsAway('textEvent', 'un_bookmark')
      } else {
        this.bookmarkColor = 'https://static.overlay-tech.com/assets/3ce440cd-3765-4ac7-8f3c-a3fca9059c31.svg'
        this.setBookmark(true)
        this.bombIsAway('textEvent', 'bookmark')
      }
      this.isSaved = !this.isSaved
      this.contentState.isSaved = !this.contentState.isSaved
      this.updateState()
    }
  },
  mounted () {
    socialvue.index()
  },
  created () {
    this.renderArticle = false
    this.bookmarkColor = require('@/assets/images/svg/bookmarks_white_18dp.svg')
    this.GET_USER_CONTENT({
      contentId: this.$route.params.contentId,
      success: () => {
        if (this.getUserContent.content.thumbnailUrl) {
          this.imageSrc = this.getUserContent.content.thumbnailUrl
        } else {
          this.imageSrc = require('../../assets/images/articlebanner.jpeg')
        }
        this.title = this.getUserContent.content.title
        this.timeTotal = this.getUserContent.content.timeTotal / 60
        this.source = this.getUserContent.content.publisher.name
        this.comments = this.getUserContent.content.comments
        this.likes = this.getUserContent.content.likes
        this.ratings = this.getUserContent.rating
        this.articleDescription = this.getUserContent.content.description
        this.isSaved = this.getUserContent.isSaved
        this.contentState.isSaved = this.getUserContent.isSaved
        this.contentState.liked = this.getUserContent.liked
        this.contentState.rating = this.getUserContent.rating
        this.contentState.timeViewed = this.getUserContent.timeViewed
        this.averageRating = (this.getUserContent.content.rating.averageRating).toFixed(1)
        this.ratingFills.star_5 = Object.values(this.getUserContent.content.rating.ratingMap)[4]
        this.ratingFills.star_4 = Object.values(this.getUserContent.content.rating.ratingMap)[3]
        this.ratingFills.star_3 = Object.values(this.getUserContent.content.rating.ratingMap)[2]
        this.ratingFills.star_2 = Object.values(this.getUserContent.content.rating.ratingMap)[1]
        this.ratingFills.star_1 = Object.values(this.getUserContent.content.rating.ratingMap)[0]
        this.totalUsersRated = this.getUserContent.content.rating.totalUsers
        this.starRatingData.star = this.getUserContent.rating
        if (this.getUserContent.liked) {
          this.likedText = 'Liked'
          this.toggle = true
          this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        } else {
          this.likedText = 'Like the Lesson'
          this.toggle = false
          this.heartColor = 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg'
        }
        if (this.getUserContent.isSaved) {
          this.bookmarkColor = 'https://static.overlay-tech.com/assets/3ce440cd-3765-4ac7-8f3c-a3fca9059c31.svg'
        } else {
          this.bookmarkColor = require('@/assets/images/svg/bookmarks_white_18dp.svg')
        }
        this.renderArticle = true
        this.setAnalyticsContentData(this.getUserContent)
        setTimeout(() => { this.fillStarRating() }, 1000)
      }
    })
    this.RESET_STATE()
    console.log('On ARTICLE_VIEW_SCREEN')
    this.setPage('ARTICLE_VIEW_SCREEN')
    this.heartBeatInterval = setInterval(() => this.sendHeartBeat(), 10000)
    // eslint-disable-next-line no-undef
    divolte.signal('pageView', this.getAnalyticsState)
  },
  beforeDestroy () {
    clearInterval(this.heartBeatInterval)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.article-page-container{
  padding-top: 1%;
  padding-bottom: 2%;
}
.circular__submenu {
  margin-top: 35px;
  margin-left: 10px;
}
.banner-image-container{
}
.article-image{
  height: 38vH;
  width: 60%;
  display: block;
  margin: 2% auto;
  border-radius: 10px;
}
.article-icon {
  margin-top: -3px;
  height: 22px;
}
.info-container {
  width: 60%;
  display: flex;
  margin: 2% auto;
  flex-direction: column;
}
.time-total {
  margin-left: 7px;
  margin-top: 2px;
}
.article-metadata {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: black;
  border-radius: 10px;
 padding-top: 10px;
  padding-left: 10px;
}
.article-source {
  margin-top: 4px;
  margin-left: 5%;
}
.heart-button {
  width: 22.16px;
  height: 16px;
  margin-right: 7px;
}
.article-ratings {
  margin-left: 5%;
  margin-top: -4px;
}
.metric_container {
  display: flex;
  color: $red;
  margin-left: 5%;
}
.article-heading {
  color: white !important;
  font-family: "Roboto" !important;
  font-weight: 300 !important;
  font-size: 28px !important;
}
.article-interaction-info {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: white !important;
}
.time-container {
  margin-right: 1%;
}
.article-metrics {
  display: flex;
  position: absolute;
  right: 26%;
  flex-direction: row;
  margin-left: 9%;
  margin-top: .5%;
}
.comments_symbol {
  width: 19.44px;
  margin-top: -19px;
  margin-right: 5.02px;
}
.heart {
  &-symbol {
    width: 22.16px;
    height: 18px;
    margin-right: 7px;
  }
  &-text {
    height: 17px;
    font-size: 14px;
    line-height: 13px;
    color: $red;
    display: flex;
   margin-top: 2px !important;
  }
}
.zoomi_index {
  &-box{
    background-color: $red;
    margin-right: 7px;
    border-radius: 4px;
    padding: 6px 3.5px 1px 4.5px;
    display: flex;
    align-items: center;
    width: 25px;
    height: 16px;
  }
  &-text {
    // height: 17px;
    font-size: 14px;
    line-height: 13px;
    color: $red;
    display: flex;
    align-items: center
  }
}
.source {
  margin-left: 7px;
}
.add-to-playlist-button {
  background-color: $red;
  border-radius: 4px;
  padding: 3px 3.5px 0px;
  display: flex;
  align-items: center;
  margin-left: 5%;
  height: 16px;
  width: 16px;
  // margin-top: 6px;
}
.bookmark {
  width: 16px;
  height: 16px;
  align-items: center;
  // margin-right: 8px;
  // margin-top: 7px;
  cursor: pointer;

}
.copy_20_square {
  font-size: 14px;
  line-height: 13px;
  color: $white;
  text-align: center;
  margin-bottom: 1px !important;
}
.p_tag {
  font-weight: 700;
  margin-top: -4px;
  & > span {
    font-weight: 400;
  }
}
.readable-text {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
.bottom-interaction-container {
  width: 97%;
  display: flex;
  flex-direction: column;
  background: black;
  border-radius: 5px;
  position: absolute;
  left: 25px;
  padding: 4vH 1vw 1vH 1vW;
}
.ratings-container {
  display: flex;
  flex-direction: row;
  margin-left: 20vW;
  text-align: right;

}
.ratings-row {
  text-align: right;
  width: 50vW;
  display: flex;
  flex-direction: row;
}
.stars {
  display: flex;
  flex-direction: column;
}
.article_rating_rect {
  height: 4px;
  width: 25vw;
  background-color: $white;
  margin: 15px;
  border-radius: 2px;
  &-red {
    background-color: $red;
    height: 6px;
    border-radius: 2px;
  }
}
.article_numerics {
  display: flex;
  flex-direction: row;
  margin-top: -105px;
}
.rating_bars_article {
  margin-top: -3px;
  display: flex;
  flex-direction: column;
}
.avg_rating_article {
  font-family: Roboto !important;
  font-weight: 200 !important;
  font-size: 13rem;
  margin-left: 2vW;
  color: $white;
}
.no_of_ratings_article {
  color: $red;
  font-size: 1.5vw;
  margin-top: 150px;
  margin-left: 2vW;
}
.rate-like {
  display: flex;
  flex-direction: row;
}

.article_interaction {
  &-rate_this_lesson {
    color: $white;
    font-size: 1.5vw;
    display: flex;
    & > p {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}
.like-interaction {
  margin-left: 15vW;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
}
.what_people_are_saying {
  position: absolute;
  right: 35px;
  height: auto;
  border-radius: 5px;
  padding: 0px 0.7vw;
  font-size: 1.5vw;
  background-color: $red;
  color: $white;
}
.article-info-like {
  height: 32px;
  width: 30px;
  margin-right: 1vW;
}

</style>
